import i18next from 'i18next';
import isEmpty from '../../helpers';

export default function validate(values) {
  let errors = {};
  if (isEmpty(values.confirmationCode)) {
    errors.confirmationCode = i18next.t('COMPONENT.CONFIRM_CODE');
  }

  if (values.password != null && values.password === '') {
    errors.password = i18next.t('SIGN.UP_NOT_VALID_PASS');
  }

  return errors;
}
