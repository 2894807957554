import axios from 'axios';
import jwt_decode from 'jwt-decode';
import config from '../config';
import isEmpty, { ifEmptyArr, ifEmptyObj, isArray } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import {
  cleanPhoneValue,
  generateSign,
  getParam,
} from '../helpers/utils';
import { isValidEmail, isValidPhone } from '../helpers/validators';
import { getBase64 } from '../helpers/base64';
import dayjs from 'dayjs';
import i18next from 'i18next';

export const DOCTOR_START_PAGE = '/app/chat';
const saveAuthToken = (data) => {
  localStorage.setItem('authToken', data?.authToken);
  if (data?.refreshToken) {
    localStorage.setItem('refreshToken', data?.refreshToken);
  }
};

export const getLang = () => {
  const lang = localStorage.getItem('i18nextLng');
  if (!lang) return config.defLang;
  return lang;
};

export const lastName = (isPets, lastName) =>
  lastName ?? (isPets ? i18next.t('PROFILE.ANONYMOUS') : 'Anonymous');
export const firstName = (isPets, firstName) =>
  firstName ?? (isPets ? null : '');

export const accountRole = {
  unknown: 0,
  patient: 1,
  agent: 2,
  doctor: 3,
  operator: 4,
};
export const savedEntites = [
  'clinics',
  'specs',
  'promoActions',
  'mmkRecordTypes',
  'notificationsTypes',
  'appInfo',
  'calypsoData',
];

export const getPromoActions = async (
  setState,
  type = 'Personal',
) => {
  try {
    const body = { type };
    setState({ isLoaded: false, date: [] });
    const { data } = await httpClient.get(
      api.url('GetPromoActions'),
      body,
    );
    if (!isEmpty(data)) {
      setState({ isLoaded: true, data });
    }

    console.log('getPromoActions', data);
    //return data;
  } catch (error) {
    setState({ isLoaded: true, date: [] });
    console.log('ERROR getPromoActions', getError(error));
  }
};

export async function getQrKey(setData) {
  try {
    const { data } = await httpClient.post(api.url('GetQrKey'));
    setData({ qrLoaded: true, ...data });
  } catch (err) {
    console.log('Err getQrKey ', err);
  }
}

export async function getQrState(
  key,
  dispatch,
  lang,
  navigate,
  isAnonymousChildrenEnabled = false,
) {
  try {
    const { data } = await httpClient.get(api.url('GetQrState'), {
      key,
      isIssuer: false,
    });
    if (data?.status === 'success') {
      saveAuthToken(data);
      dispatch({ type: 'LOADING' });
      loginUserData({
        dispatch,
        lang,
        navigate,
        callFromLogin: true,
        isAnonymousChildrenEnabled,
      });
    }
  } catch (err) {
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
    console.log('Err getQrKey ', err);
  }
}

export async function getVersion(setVersion, old) {
  const path = config.nodeEnv === 'development' ? '' : 'static/js';
  const url = `${path}/version.json`;
  try {
    const { data } = await axios.get(
      `${url}?time=${dayjs().format('YYYYMMDD-HH:mm:ss:SSS')}`,
    );
    if (old == null) {
      console.log(
        '==========================\nversion: ',
        data.version,
        '\ndate version: ',
        dayjs(data.date).format('DD.MM.YYYY HH:mm'),
        '\n========================== ',
      );
    }

    setVersion({
      old,
      new: data.hash,
      date: data.date,
      version: data.version,
    });
  } catch (err) {
    console.log('Err version ', err);
  }
}

export async function brandLogoImage(brandId, setBrand) {
  await httpClient
    .get(api.url('GetBrandLogoImage'), {
      brandId,
    })
    .then(async ({ data }) => {
      console.log('GetBrandLogoImage', data);
      setBrand(data);
    })
    .catch(async (err) => {
      console.log('Err GetBrandLogoImage ', getError(err));
    });
}

export async function agreementText({
  agreement,
  setAgreement,
  isLocal = true,
  header,
}) {
  setAgreement({
    ...agreement,
    loading: true,
  });

  try {
    if (isLocal) {
      const url = `/${config.theme}/assets/agreement.txt`;
      const { data } = await axios.get(url, {
        headers: {
          'Content-Length': 0,
          'Content-Type': 'text/plain',
        },
        responseType: 'text',
      });
      //console.log('data', data);
      setAgreement({
        ...agreement,
        loading: true,
        text: data,
        header,
      });
    }

    if (!isLocal) {
      const { data } = await httpClient.get(
        api.url('GetAppLicenseAgreementText'),
        {
          platform: 'web',
        },
        { 'app-сode': config.APP_CODE },
      );
      //console.log('GetAppLicenseAgreementText');
      setAgreement({
        ...agreement,
        loading: true,
        text: data.text,
        header,
      });
    }
  } catch (err) {
    console.log('Err GetAppLicenseAgreementText ', getError(err));
    setAgreement({
      ...agreement,
      loading: true,
      error: getError(err),
    });
  }
}

export async function singUpConfirmationCode(
  dispatch,
  values,
  lang,
  navigate,
  isAnonymousChildrenEnabled,
  doCreateVisitAndLogin = false,
) {
  const body = {
    login: values.login,
    password: values.password,
    confirmationCode: values.confirmationCode,
  };

  dispatch({ type: 'LOADING' });
  try {
    const { data } = await httpClient.post(
      api.url('ConfirmNewAccount'),
      body,
      {
        config: { isWrap: false },
      },
    );
    saveAuthToken(data);
    if (data != null && data.authToken) {
      //console.log('== === ConfirmNewAccount == === ', data);
      if (doCreateVisitAndLogin) {
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: { action: 'MAY_LOGIN' },
        });
        return;
      }
      loginUserData({
        dispatch,
        lang,
        navigate,
        callFromLogin: true,
        role: data.role,
        isAnonymousChildrenEnabled,
      });
    } else {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { action: 'WRONG_RESPONSE_IS_EMPTY' },
      });
    }
  } catch (err) {
    //console.log('ERROR singUpConfirmationCode ==', err, '');
    if (getError(err, 'code') === 'WRONG_CONFIRMATION_CODE')
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { action: 'WRONG_CONFIRMATION_CODE' },
      });
    else
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { action: getError(err) },
      });
  }
}

export async function changeEmailConfirmationCode(
  dispatch,
  serverResponse,
  values,
  user,
) {
  dispatch({ type: 'LOADING' });

  await httpClient
    .post(
      api.url('ConfirmNewEmail'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });

      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          ...serverResponse,
          action: getError(err, 'code'),
          error: getError(err),
        },
      });
    });
}
export async function changePhoneConfirmationCode(
  dispatch,
  serverResponse,
  values,
  user,
) {
  dispatch({
    type: 'LOADING',
  });

  await httpClient
    .post(
      api.url('ConfirmNewPhone'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          ...serverResponse,
          action: getError(err, 'code'),
          error: getError(err),
        },
      });
    });
}
export async function deleteUserPhoto(dispatch, user) {
  dispatch({
    type: 'LOADING',
  });

  await httpClient
    .delete(api.url('DeleteUserPhoto'), {
      mmkId: user.mmkId,
      clinicId: user.clinicId,
    })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: 'SET_USER',
          payload: {
            serverResponse: { action: 'PHOTO_DELETED' },
            user: { ...user, photoUrl: '', photo: null },
          },
        });
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user, photoUrl: '', photo: null }),
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: getError(err, 'code'),
          error: getError(err),
        },
      });
    });
}
export async function uploadUserPhoto(
  dispatch,
  userData,
  fileAttachment,
) {
  const authToken = localStorage.getItem('authToken');

  let formData = new FormData();
  const body = {
    appCode: config.APP_CODE,
    authToken,
    clinicId: userData.clinicId,
    mmkId: userData.mmkId,
  };
  //console.log('uploadUserPhoto fileAttachment \n', fileAttachment);
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  formData.append('sign', generateSign(body, config.secretKey));
  formData.append('photo', fileAttachment);
  dispatch({
    type: 'LOADING',
  });

  try {
    const { data } = await httpClient.post(
      api.url('UploadUserPhoto'),
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
        config: { isWrap: false },
      },
    );

    if (data) {
      //console.log('SET_USER_PHOTO data', data);
      dispatch({
        type: 'SET_USER_PHOTO',
        payload: {
          serverResponse: { action: 'PHOTO_UPLOAD_OK' },
          user: { ...userData, photo: data },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...userData, photo: data }),
      );
    }
  } catch (err) {
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: {
        action: getError(err, 'code'),
        error: getError(err),
      },
    });
  }
}

export async function updateUserInfo(
  dispatch,
  mmkId,
  clinicId,
  userInfo,
  user,
) {
  const body = {
    mmkId,
    clinicId,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    middleName: userInfo.middleName,
    gender: userInfo.gender,
    phone: userInfo.phone,
    birthDate: userInfo.birthDate,
    dmsName: userInfo.dmsName,
    dmsNumber: userInfo.dmsNumber,
    dmsEndDate: userInfo.dmsEndDate,
    omsName: userInfo.omsName,
    omsNumber: userInfo.omsNumber,
  };
  dispatch({
    type: 'LOADING',
  });

  await httpClient
    .post(api.url('UpdateUserInfo'), body)
    .then(({ data }) => {
      //console.log('----- UpdateUserInfo  -----', data);
      dispatch({
        type: 'SET_USER',
        payload: {
          user: { ...user, ...body },
          serverResponse: { action: 'OK', ...data },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...body }),
      );
    })
    .catch((err) => {
      //console.log('Err updateUserInfo ', err);
      dispatch({
        type: 'SET_USER',
        payload: {
          serverResponse: {
            action: getError(err, 'code'),
            error: getError(err),
          },
          user: { ...user },
        },
      });
    });
}
export async function downloadPhoto({
  setPhotoData,
  photoUrl,
  appCode,
  authToken,
  userDispatch,
  mmkId,
}) {
  setPhotoData({ photo: null, isLoaded: false });

  try {
    const { data } = await axios.get(photoUrl, {
      headers: {
        'app-code': appCode,
        Authorization: `Bearer ${authToken}`,
      },
      responseType: 'blob',
    });

    let photo = '';
    try {
      photo = await getBase64(data);
      photo = photo.split(',')[1];
    } catch (error) {
      console.error(error);
    }

    setPhotoData({ photo, isLoaded: true });
    userDispatch({
      type: 'SET_MMK_LIST_PHOTO',
      payload: { mmkId, photo },
    });
  } catch (err) {
    console.log('ERROR downloadPhoto =', getError(err));
    setPhotoData({ photo: null, isLoaded: true });
  }
}

export async function changeUser({
  dispatch,
  mmkLinkedList,
  mmkId = 'parent',
  clinicId = null,
  withPhoto = true,
  isAnonymousChildrenEnabled = false,
  phoneParent,
}) {
  try {
    const { data } = await httpClient.get(api.url('GetUserInfo'), {
      mmkId,
      clinicId,
      withPhoto,
    });
    if (isEmpty(data)) {
      throw new Error('Error: GetUserInfo ie empty');
    }

    const payload = {
      user: {
        ...data,
        mmkId,
        clinicId,
        isAnonymous: data.canDeleteAccount,
        firstName: firstName(
          isAnonymousChildrenEnabled,
          data.firstName,
        ),
        middleName: data.middleName ?? '',
        lastName: lastName(isAnonymousChildrenEnabled, data.lastName),
        phoneParent,
      },

      mmkLinkedList: mmkLinkedList.map((item) => ({
        ...item,
        photo: item.number === mmkId ? data.photo : item.photo,
      })),
    };
    //console.log(' = user =', payload);
    localStorage.setItem('user', JSON.stringify(payload.user));
    setDefaultMmkId({ mmkId, clinicId });
    dispatch({
      type: 'SET_USER_WITHOUT_LOADING',
      payload,
    });
  } catch (error) {
    console.log('ERROR changeUser =', getError(error));
    dispatch({
      type: 'SET_USER',
      payload: {
        serverResponse: {
          action: getError(error, 'code'),
          error: getError(error),
        },
      },
    });
  }
}

export async function deleteUser(dispatch) {
  dispatch({
    type: 'LOADING',
  });
  await httpClient
    .delete(api.url('DeleteAccount'))
    .then(({ data }) => {
      //const serverResponse = data === 'OK' ? 'ACCOUNT_DELETED' : data;
      console.log('= axios  DeleteAccount =', data);
      if (data === 'OK')
        dispatch({
          type: 'SET_USER',
          payload: {
            serverResponse: {
              action: 'ACCOUNT_DELETED',
            },
          },
        });
    })
    .catch((err) => {
      console.log('= axios ERROR DeleteAccount =', getError(err));
      dispatch({
        type: 'SET_USER',
        payload: {
          serverResponse: {
            action: getError(err, 'code'),
            error: getError(err),
          },
        },
      });
    });
}

export async function createUser(
  dispatch,
  values,
  countryCode = 'RU',
) {
  dispatch({ type: 'LOADING' });
  const login = {};
  if (isValidEmail(values.login)) login.email = values.login;
  else if (isValidPhone(values.login, countryCode))
    login.phone = cleanPhoneValue(values.login);

  const body = {
    ...login,
    password: values.password,
    lastName: values.lastName,
    firstName: values.firstName,
    middleName: values.middleName,
    birthDate: values.birthDate,
    gender: values.gender,
    registrationSource: 'web',
  };

  await httpClient
    .post(api.url('RegisterNewUser'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      if (!isEmpty(data)) {
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: {
            ...data,
            login: values.login,
            password: values.password,
            action: 'CONFIRM_CODE_SENT',
          },
        });
      } else {
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: { action: 'WRONG_RESPONSE_REG_USER_IS_EMPTY' },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { action: getError(err) },
      });
    });
}

export async function initPasswordChanging(dispatch, values) {
  dispatch({ type: 'LOADING' });
  const body = { login: values.login };
  await httpClient
    .post(api.url('InitPasswordChanging'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { ...data, action: 'CONFIRM_CODE_SENT' },
      });
    })
    .catch((err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { error: getError(err) },
      });
    });
}

export async function changePassword(dispatch, values) {
  dispatch({ type: 'LOADING' });
  const body = {
    login: values.login,
    confirmationCode: values.confirmationCode,
    newPassword: values.password,
  };

  await httpClient
    .post(api.url('ChangePassword'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: { action: data },
      });
    })
    .catch((error) => {
      console.log('Err ChangePasswordResult ==', getError(error));
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          error: getError(error),
          action: 'WRONG_CONFIRMATION_CODE',
        },
      });
    });
}

//  reset auth & localStorage
// localStorage.removeItem('doctor');
// localStorage.removeItem('user');
// localStorage.removeItem('authToken');
// savedEntites.forEach((key) => {
//   localStorage.removeItem(key);
// });

export function clearLocalStorage() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
  localStorage.removeItem('doctor');
  localStorage.removeItem('chatUser');
  localStorage.removeItem('mmkLinkedList');
  localStorage.removeItem('anketaClosed');
  savedEntites.forEach((key) => {
    localStorage.removeItem(key);
  });
  localStorage.removeItem('visitData');
}
export async function signOut(
  dispatch = null,
  navigate = null,
  path = '/',
  isRedirect = false,
) {
  try {
    await httpClient.post(api.url('Logout'));
  } catch (error) {
    console.log('ERROR Logout', getError(error));
  }

  clearLocalStorage();
  console.log('\n\n\n\n ===== done SignOut ===== \n\n\n\n ');
  if (dispatch != null) dispatch({ type: 'SIGN_OUT_SUCCESS' });
  if (navigate != null) navigate(path);
  const accessToken = getParam('access_t');
  if (isRedirect || accessToken) window.location.href = path;
}
export async function getRefreshToken(
  authToken,
  refreshToken,
  signOut,
) {
  try {
    const { data } = await httpClient.post(api.url('RefreshToken'), {
      authToken,
      refreshToken,
    });
    saveAuthToken(data);
    window.location.href = '/';
  } catch (error) {
    console.log('ERROR RefreshToken', getError(error));
    signOut(null, null, '/', true);
  }
}

export async function getLinkedMmkList({
  dispatch,
  userData,
  isAnonymousChildrenEnabled,
  phoneParent,
}) {
  const body = {};
  dispatch({
    type: 'LOADING',
  });
  await httpClient
    .get(api.url('GetLinkedMmkList'), body)
    .then(async ({ data }) => {
      if (isArray(data)) {
        let mmkLinkedList = [...userData.mmkLinkedList, ...data];

        mmkLinkedList = [
          ...new Set(mmkLinkedList.map((obj) => obj.number)),
        ].map((number) =>
          mmkLinkedList.find((obj) => obj.number === number),
        );

        dispatch({
          type: 'SET_MMK_LINKED_LIST',
          payload: mmkLinkedList,
        });

        const defaultMmkId = await getDefaultMmkId();

        if (defaultMmkId != 'patent') {
          const mmk = mmkLinkedList.find(
            (it) => it.number === defaultMmkId,
          );
          changeUser({
            dispatch,
            mmkLinkedList,
            mmkId: mmk.number,
            clinicId: mmk.clinicId,
            isAnonymousChildrenEnabled,
            showLoading: false,
            phoneParent,
          });
        } else if (isAnonymousChildrenEnabled) {
          changeUser({
            dispatch,
            mmkLinkedList,
            mmkId: mmkLinkedList[1].number,
            clinicId: mmkLinkedList[1].clinicId,
            isAnonymousChildrenEnabled,
            showLoading: false,
            phoneParent,
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: 'LOADED',
      });
      console.log('ERROR GetLinkedMmkList =', getError(err));
    });
}
export async function getDictsData(dispatch) {
  const reqs = [
    {
      name: 'GetFilials',
      body: {
        includeNotVisible: true,
      },
    },
    {
      name: 'GetDoctorSpecializations',
      body: {
        startIndex: 0,
        searchValue: '',
        count: 300,
        clinicId: 0,
        sortOrder: 'preset',
      },
    },
    {
      name: 'GetPromoActions',
      body: { type: 'Action' },
    },
    {
      name: 'GetMmkRecordTypes',
      body: {},
    },
    {
      name: 'GetNotificationTypes',
      body: {},
    },
    {
      name: 'AppInfo',
      body: {},
    },
    {
      name: 'CalypsoForms',
      body: {},
    },
  ];

  const totalRequest = reqs.map((req) =>
    httpClient.get(api.url(req.name), req.body),
  );

  try {
    const res = await axios.all(totalRequest);
    const dictData = {
      clinics: ifEmptyArr(res[0].data),
      specs: ifEmptyArr(res[1].data),
      promoActions: ifEmptyArr(res[2].data),
      mmkRecordTypes: ifEmptyArr(res[3].data),
      notificationsTypes: ifEmptyArr(res[4].data),
      appInfo: ifEmptyObj(res[5].data),
      calypsoData: ifEmptyArr(res[6].data),
    };

    dispatch({
      type: 'SET_DICTS',
      payload: dictData,
    });

    //gettinUserData(dictData.appInfo?.isAnonymousChildrenEnabled);
  } catch (err) {
    dispatch({
      type: 'SET_USER',
      payload: {
        serverResponseDictsError: getError(err),
      },
    });
    console.log('==== error getDictsData ==== \n', getError(err));
  }
}

export const getDefaultMmkId = async () => {
  try {
    const { data } = await httpClient.get(api.url('GetDefaultMmkId'));
    if (isEmpty(data)) {
      throw new Error('defaultMmkId is empty');
    }
    console.log('== defaultMmkId == ', data.defaultMmkId);
    return data.defaultMmkId;
  } catch (err) {
    console.log('Error getDefaultMmkId --- \n', getError(err));
  }
};
export const setDefaultMmkId = async (body) => {
  try {
    const { data } = await httpClient.post(
      api.url('SetDefaultMmkId'),
      body,
    );
    console.log('== setDefaultMmkId defaultMmkId == ', data);
  } catch (err) {
    console.log('Error setDefaultMmkId --- \n', getError(err));
  }
};

export async function loginUserData({
  dispatch,
  lang,
  navigate = null,
  callFromLogin = false,
  role = accountRole.patient,
  isAnonymousChildrenEnabled = false,
  mmkId = 'parent',
}) {
  const body = {
    mmkId,
    clinicId: 0,
    withPhoto: true,
  };
  await httpClient
    .get(api.url('GetUserInfo'), body)

    .then(async ({ data }) => {
      if (callFromLogin) {
        dispatch({
          type: 'LOADED',
        });
      }

      const clinicId = 0;

      const fio = `${lastName(
        isAnonymousChildrenEnabled,
        data?.lastName,
      )} ${data?.firstName ?? ''} ${data?.middleName ?? ''}`;

      const mmkLinkedList = [
        { clinicId, number: 'parent', name: fio, photo: data.photo },
      ];
      const authToken = localStorage.getItem('authToken');
      const phoneParent = data.phone;
      const userData = {
        user: !isEmpty(data)
          ? {
              ...data,
              mmkId,
              clinicId,
              firstName: firstName(
                isAnonymousChildrenEnabled,
                data.firstName,
              ),
              middleName: data.middleName ?? '',
              lastName: lastName(
                isAnonymousChildrenEnabled,
                data.lastName,
              ),
              isAnonymous: data.canDeleteAccount,
              role,
              lang,
              phoneParent,
            }
          : { lang, role },

        mmkLinkedList,
        authToken,
      };

      localStorage.setItem('user', JSON.stringify(userData.user));

      dispatch({
        type: 'LOGIN_PATIENT',
        payload: userData,
      });

      if (!userData.user.isAnonymous || isAnonymousChildrenEnabled) {
        console.log('\n === userData ===\n', userData);
        getLinkedMmkList({
          dispatch,
          userData,
          isAnonymousChildrenEnabled,
          phoneParent,
        });
      }
      if (getParam('access_t') != null) {
        //window.location.href = '/';
        navigate('/');
      }
    })
    .catch((err) => {
      console.log('==== error loginUserData ==== \n', getError(err));
      if (callFromLogin) {
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: getError(err),
        });
      }
    });
}

export async function loginUser(
  dispatch,
  emailOrPhone,
  password,
  navigate,
  setValsTab,
  valsTab,
  lang,
  isAnonymousChildrenEnabled = false,
) {
  dispatch({ type: 'LOADING' });

  const login = {};
  login['login'] = emailOrPhone;

  await httpClient
    .post(api.url('ExternalLogin'), {
      ...login,
      password,
    })
    .then(({ data }) => {
      saveAuthToken(data);
      if (data?.isPasswordExpired) {
        dispatch({ type: 'LOADED' });
        setValsTab({
          ...valsTab,
          activeTabId: 2,
          login: emailOrPhone,
          warningNote: 'EXP_PASS',
        });

        return;
      }
      if (data?.isTwoFactorAuth) {
        dispatch({ type: 'LOADED' });
        setValsTab({
          ...valsTab,
          activeTabId: 0,
          login: emailOrPhone,
          warningNote: 'CONFIRM_AUTH',
          confirmationCodeInfo: data?.confirmationCodeInfo,
        });

        return;
      }
      //console.log(' ExternalLogin --- \n', data);
      return data;
    })
    .then((data) => {
      if (!isEmpty(data))
        loginUserData({
          dispatch,
          lang,
          navigate,
          callFromLogin: true,
          role: data?.role,
          isAnonymousChildrenEnabled,
        });
    })
    .catch((err) => {
      console.log('Error ExternalLogin --- \n', getError(err));
      if (err?.response?.data?.code === 'ACCOUNT_IS_NOT_CONFIRMED') {
        requestNewAccountConfirmationCode(dispatch, setValsTab, {
          ...valsTab,
          login: emailOrPhone,
          password,
        });
        return;
      }
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: getError(err),
      });
    });
}

export const confirmLogin = async (
  dispatch,
  code,
  navigate,
  lang,
  isAnonymousChildrenEnabled = false,
) => {
  try {
    dispatch({ type: 'LOADING' });

    const { data } = await httpClient.post(api.url('СonfirmLogin'), {
      code,
    });
    console.log('== confirmLogin == ', data);
    saveAuthToken(data);
    if (data?.authToken != null)
      loginUserData({
        dispatch,
        lang,
        navigate,
        callFromLogin: true,
        role: data?.role,
        isAnonymousChildrenEnabled,
      });
  } catch (err) {
    console.log('Error confirmLogin --- \n', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
  }
};

export const getSessions = async (dispatch) => {
  try {
    const { data } = await httpClient.get(api.url('GetSessions'));
    dispatch({
      type: 'FETCH_SESSIONS',
      payload: ifEmptyArr(data),
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_SESSIONS_ERROR',
      payload: getError(error),
    });

    console.log('ERROR GetSessions', getError(error));
  }
};
export const requestNewAccountConfirmationCode = async (
  dispatch,
  setValsTab,
  valsTab,
) => {
  dispatch({ type: 'LOADING' });
  try {
    const { login, password } = valsTab;
    const { data } = await httpClient.post(
      api.url('NewAccountConfirmationCode'),
      { login, password },
    );
    if (!isEmpty(data)) {
      dispatch({ type: 'LOADED' });
      setValsTab({
        ...valsTab,
        activeTabId: 0,
        warningNote: 'CONFIRM_SINGUP',
        confirmationCodeInfo: data,
      });
    } else {
      throw Error('error requestNewAccountConfirmationCode');
    }
  } catch (error) {
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(error),
    });

    console.log('ERROR GetSessions', getError(error));
  }
};

export const deleteSession = async (dispatch, jti) => {
  try {
    await httpClient.delete(api.url('DeleteSession'), { jti });
    dispatch({
      type: 'DELETE_SESSION',
      payload: jti,
    });
  } catch (error) {
    console.log('ERROR GetSessions', getError(error));
    dispatch({
      type: 'FETCH_SESSIONS_ERROR',
      payload: getError(error),
    });
  }
};

/** --------------------- Operator --------------------------------------- */
export const getChatOperatorInfo = async (setChatInfo) => {
  try {
    const { data } = await httpClient.get(
      api.url('GetChatOperatorInfo'),
    );

    setChatInfo({
      ...data,
    });
  } catch (error) {
    console.log('ERROR getChatOperatorInfo', getError(error));
  }
};

/**---------------- Doctor  ------------------------------- */
export const getClinics = async (dispatch) => {
  await httpClient
    .get(api.url('GetFilials'), {})
    .then(async ({ data }) => {
      dispatch({
        type: 'SET_CLINICS',
        payload: data,
      });
      //console.log('res', res?.Data);
    })
    .catch(async (err) => {
      console.log('---Error getting clinics--- \n', getError(err));
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: getError(err),
      });
    });
};
export async function authDataDoctor(
  dispatch,
  lang,
  clinicId,
  isRedirect = false,
) {
  dispatch({
    type: 'LOADING',
  });

  const reqs = [
    {
      name: 'GetUserInfo',
      body: {
        clinicId,
      },
    },
    {
      name: 'GetMmkRecordTypes',
      body: {},
    },
    {
      name: 'GetDoctorSpecializations',
      body: {
        startIndex: 0,
        searchValue: '',
        count: 1000,
        clinicId,
      },
    },
  ];
  const requests = reqs.map((req) =>
    httpClient.get(api.url(req.name), req.body),
  );

  try {
    const r = await axios.all(requests);

    const resUserInfo = r[0].data;
    const resMmkRecordTypes = r[1].data;
    const resSpec = r[2].data;

    let payload = {};
    if (!isEmpty(r)) {
      payload = {
        doctor: {
          data: true,
        },
        user: {
          ...resUserInfo,
          lang,
          clinicId,
          mmkId: 'parent',
          role: accountRole.doctor,
        },
        mmkRecordTypes: ifEmptyArr(resMmkRecordTypes),
        specs: ifEmptyArr(resSpec),
      };
    }

    dispatch({
      type: 'LOGIN_DOCTOR',
      payload,
    });
    if (isRedirect) window.location.href = `/#${DOCTOR_START_PAGE}`;
  } catch (err) {
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });

    //signOut(dispatch, navigate, '/docsign');
    console.log('Err GetCalypsoServerParams ==', err);
  }
}
export async function authDoctorCalypso() {
  try {
    const { data } = await httpClient.get(api.url('AppsTokens'));
    console.log('data', data);
    const url = `${data.address}/?theme=${config.theme}&token=${data.accessToken}&refreshToken=${data.refreshToken}&returmUrl=${window.location.href}`;
    window.location.href = url;
  } catch (error) {
    console.log('ERROR authDataDoctorCalypso', getError(error));
  }
}

export async function loginDoctor(
  dispatch,
  loginOrEmail,
  password,
  clinicId,
  medDepId,
  lang = config.defLang,
) {
  if (loginOrEmail.length > 0 && password.length > 0) {
    dispatch({
      type: 'LOADING',
    });

    const body = { clinicId, medDepId, password };
    body['login'] = loginOrEmail;
    await httpClient
      .post(api.url('LoginAsDoctor'), body)
      .then(async ({ data }) => {
        const authToken = data?.authToken;
        const refreshToken = data?.refreshToken;

        const login = {};
        login['login'] = loginOrEmail;
        saveAuthToken({ authToken, refreshToken });
        if (medDepId === 0 && isEmpty(data?.medDepList)) {
          dispatch({
            type: 'SET_USER',
            payload: {
              serverResponse: 'WRONG_LOGIN_OR_PASSWORD',
            },
          });
          return;
        }
        dispatch({
          type: 'SET_USER',
          payload:
            medDepId === 0
              ? {
                  doctor: {
                    ...login,
                    password,
                    medDepList: data?.medDepList,
                    isTwoFactorAuth: data?.isTwoFactorAuth ?? true,

                    step: 1,
                  },
                  authToken,
                  error: '',
                }
              : {
                  doctor: {
                    clinicId,
                    medDepId,
                    confirmationCodeInfo: data?.confirmationCodeInfo,
                    isTwoFactorAuth: data?.isTwoFactorAuth ?? true,
                    step: data?.isTwoFactorAuth === true ? 2 : 0,
                  },
                  authToken,
                  error: '',
                },
        });
        if (medDepId > 0 && !data?.isTwoFactorAuth) {
          authDataDoctor(dispatch, lang, clinicId);
        }
      })
      .catch((err) => {
        console.log('---Error LoginAsDoctor --- \n', err);
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: getError(err),
        });
      });
  } else {
    dispatch({ type: 'LOGIN_DOCTOR_FAILURE' });
  }
}

export async function sendLoginAsDoctorConfirmationCode(
  dispatch,
  code,
  clinicId,
  medDepId,
  lang,
) {
  dispatch({
    type: 'LOADING',
  });
  const body = {
    code,
    clinicId,
    medDepId,
  };

  await httpClient
    .post(api.url('SendLoginAsDoctorConfirmationCode'), body)
    .then(async ({ data }) => {
      const authToken = data?.authToken;
      const refreshToken = data?.refreshToken;
      saveAuthToken({ authToken, refreshToken });
      dispatch({
        type: 'SET_DOC_DATA',
        payload: {
          login: null,
          password: null,
        },
      });

      authDataDoctor(dispatch, lang, clinicId);
    })
    .catch(async (err) => {
      dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: getError(err),
      });

      setTimeout(() => {
        dispatch({
          type: 'SET_DOC_DATA',
          payload: { confirmationCodeInfo: null },
        });

        //navigate('/docsign');
      }, 3000);

      console.log('Err SendLoginAsDoctorConfirmationCode ==', err);
    });
}

export const callDoctor = async (dispatch, body, callback) => {
  //console.log('callDoctor', body);
  try {
    const { data } = await httpClient.post(
      api.url('CallDoctor'),
      body,
    );
    if (data != null) {
      dispatch({ isLoaded: true, data, posted: true });
      callback();
    }
  } catch (error) {
    console.log('ERROR callDoctor', getError(error));
    dispatch({
      isLoaded: true,
      posted: true,
      serverError: getError(error),
    });
  }
};

/** ------------------------------------------------------------ */
export const loginByToken = async (
  dispatch,
  lang,
  isAnonymousChildrenEnabled,
) => {
  //const token = localStorage.getItem('authToken');
  const token = getParam('token');
  if (token == null) return;
  if (token != null) {
    const decoded = jwt_decode(token);
    if (decoded == null) signOut(dispatch);
  }

  try {
    const url = `${config.baseURLApi}auth/tokenData`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    if (data == null) signOut(dispatch);

    saveAuthToken({ authToken: token });
    if (data?.role === accountRole.patient) {
      loginUserData({
        dispatch,
        lang,
        navigate: null,
        isAnonymousChildrenEnabled,
      });
    }
    if (data?.role === accountRole.doctor) {
      authDataDoctor(dispatch, lang, data?.meddepClinicId, true);
    }

    //localStorage.setItem('chatUser', JSON.stringify(data));
  } catch (error) {
    console.log('ERROR chekToken', getError(error));
    signOut(dispatch);
  }
};

export const confirmAuth = async (
  dispatch,
  lang,

  token,
  isAnonymousChildrenEnabled = false,
) => {
  dispatch({
    type: 'LOADING',
  });
  try {
    const body = { token };
    const { data } = await httpClient.post(
      api.url('ConfirmAuth'),
      body,
    );
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: data,
    });
    if (data === true) {
      loginUserData({
        dispatch,
        lang,
        isAnonymousChildrenEnabled,
      });
    }
  } catch (err) {
    console.log('ERROR confirmAuth', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
  }
};

export const medicalNets = async () => {
  try {
    const body = {};
    const { data } = await httpClient.get(
      api.url('MedicalNets'),
      body,
    );

    console.log('medicalNets', data);
    return data;
  } catch (error) {
    console.log('ERROR medicalNets', getError(error));
  }
};

export const esiaAuthLink = async (setEisa, dispatch) => {
  setEisa({ link: null, isLoaded: false });
  try {
    const body = {};
    const { data } = await httpClient.get(
      api.url('EsiaAuthLink'),
      body,
    );
    if (data?.link) setEisa({ link: data.link, isLoaded: true });
  } catch (err) {
    setEisa({ link: null, isLoaded: true });
    console.log('ERROR esiaAuthLink', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
  }
};
export const esiaAuthCode = async ({
  dispatch,
  code,
  lang,
  navigate,
  isAnonymousChildrenEnabled = false,
}) => {
  dispatch({
    type: 'LOADING',
  });
  try {
    const body = { code };
    const { data } = await httpClient.post(
      api.url('EsiaAuthCode'),
      body,
    );
    console.log(' --  EsiaAuthCode -- ', data);
    saveAuthToken(data);
    if (data?.authToken != null) {
      loginUserData({
        dispatch,
        lang,
        navigate,
        isAnonymousChildrenEnabled,
      });
      // setTimeout(() => {
      //   window.close();
      // }, 2000);
    }
  } catch (err) {
    console.log('ERROR EsiaAuthCode', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
  }
};

export const error = async ({ dispatch, platform, errorText }) => {
  dispatch({
    type: 'LOADING',
  });
  try {
    const body = { platform, errorText };
    const { data } = await httpClient.post(api.url('Error'), body);
    console.log(' --  Error -- ', data);

    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: { action: 'OK', text: 'Письмо отправлено' },
    });
  } catch (err) {
    console.log('ERROR ', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: { action: 'ERR', error: getError(err) },
    });
  }
};

export const getNotificationSettings = async ({
  dispatch,
  clinicId = 0,
  mmkId = 'parent',
}) => {
  dispatch({
    type: 'LOADING',
  });
  try {
    const body = { clinicId, mmkId };
    const { data } = await httpClient.get(
      api.url('GetNotificationSettings'),
      body,
    );
    //console.log(' --  GetNotificationSettings -- ', data);

    dispatch({
      type: 'SET_NOTIFY_SETTINGS',
      payload: data,
    });
  } catch (err) {
    //console.log('ERROR GetNotificationSettings', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: { action: 'ERR', error: getError(err) },
    });
  }
};

export const saveNotificationSettings = async ({
  dispatch,
  clinicId = 0,
  mmkId = 'parent',
  settings = [],
}) => {
  // dispatch({
  //   type: 'LOADING',
  // });
  try {
    const body = { clinicId, mmkId, settings };
    const { data } = await httpClient.post(
      api.url('SaveNotificationSettings'),
      body,
    );
    //console.log(' --  SaveNotificationSettings -- ', data);

    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: 'OK',
    });
  } catch (err) {
    //console.log('ERROR SaveNotificationSettings', getError(err));
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });
  }
};
