import React, { useMemo } from 'react';
import { DEFAULT_PAGE_SIZE } from '../actions/invoices';

import { useUserStateDispatch } from './UserContext';

const InvoicesContext = React.createContext();
const initInvoices = {
  isLoaded: false,
  data: [],
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  hasMore: true,
  responseError: null,
};

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INVOICE':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: [
            {
              ...action.payload.data,
              invoiceDetails: {
                paidData: {
                  modalPaid: false,
                },
                data: [],
                isLoaded: true,
              },
            },
          ],
        },
      };
    case 'CHECK_INVOICE':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) =>
            item.id === action.payload.invoiceId
              ? {
                  ...item,
                  ...action.payload.data,
                  invoiceDetails: {
                    ...item.invoiceDetails,
                    isLoaded: true,
                  },
                }
              : item,
          ),
        },
      };

    case 'FETCH_INVOICES':
      return {
        ...state,
        invoices: {
          isLoaded: true,
          data: [
            ...state.invoices.data.filter(
              (item) =>
                !action.payload.data
                  .map((it) => it.id)
                  .includes(item.id),
            ),
            ...action.payload.data.map((item) => ({
              ...item,
              invoiceDetails: {
                isLoaded: true,
                paidData: {
                  modalPaid: false,
                },
                data: [],
              },
            })),
          ],
          pageIndex:
            state.invoices.pageIndex + state.invoices.pageSize,
          pageSize: state.invoices.pageSize,
          hasMore:
            action.payload.data.length === state.invoices.pageSize,
        },
      };

    case 'FETCH_INVOICES_ERROR':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          responseError: action.payload,
        },
      };

    case 'FETCH_INVOICE_DETAILS': {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload.invoiceId
                ? {
                    ...item.invoiceDetails,
                    ...action.payload,
                    isLoaded: true,
                  }
                : item.invoiceDetails,
          })),
        },
      };
    }
    case 'FETCH_INVOICE_DETAILS_ERROR':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload.invoiceId
                ? {
                    ...item.invoiceDetails,
                    ...action.payload,
                    paidData: {
                      modalPaid: false,
                    },
                    data: [],
                    isLoaded: true,
                  }
                : item.invoiceDetails,
          })),
        },
      };
    case 'RESET_INVOCE_PAID_DATA':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload
                ? {
                    ...item.invoiceDetails,
                    paidData: {
                      modalPaid: false,
                    },

                    isLoaded: true,
                  }
                : item.invoiceDetails,
          })),
        },
      };
    case 'RESET_INVOCE_PAID_CLOSE_MODALE':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload
                ? {
                    ...item.invoiceDetails,
                    paidData: {
                      ...item.invoiceDetails.modalPaid,
                      modalPaid: false,
                    },
                    isLoaded: true,
                  }
                : item.invoiceDetails,
          })),
        },
      };
    case 'FETCH_INVOICE_DETAILS_LOADIG':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          isLoaded: true,
          data: state.invoices.data.map((item) => ({
            ...item,
            invoiceDetails:
              item.id === action.payload.invoiceId
                ? { ...item.invoiceDetails, isLoaded: false }
                : item.invoiceDetails,
          })),
        },
      };

    case 'SET_FILTER':
      return {
        ...state,
        ...action.payload,
      };

    case 'REST_INVOICES':
      return {
        ...state,
        invoices: initInvoices,
        //paymentStatus: 'Unpaid',
        //dateRange: [null, null],
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const InvoicesProvider = ({ children }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const [state, setState] = React.useReducer(rootReducer, {
    invoices: initInvoices,
    paymentStatus: 'Unpaid',
    dateRange: [null, null],
    local: appInfo?.useLocalInvoices ?? true,
  });
  const value = useMemo(() => ({ state, setState }), [state]);
  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  );
};

const useInvoicesState = () => {
  const context = React.useContext(InvoicesContext);
  return context;
};

export { InvoicesProvider, InvoicesContext, useInvoicesState };
