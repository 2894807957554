import React, { useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useInvoicesState } from '../../context/InvoicesContext';
import {
  getInvoiceById,
  getInvoicePayInfo,
  getInvoices,
  getInvoiceUnBlock,
} from '../../actions/invoices';

//import { getClinics } from "../../context/VisitContext";
import { useUserStateDispatch } from '../../context/UserContext';

import { makeStyles } from '@mui/styles';
//import Typography from "@mui/material/Typography";
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import InvoicesItem from './InvoicesItem';
import isEmpty from '../../helpers';
import IframePaid from './IframePaid';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
  dateToHL7,
} from '../../helpers/dateFormat';
import Modale from '../../components/Modals/Modale';

import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { Alert, Box, Button } from '@mui/material';
import useInterval from '../../hooks/useInterval';
//import useInterval from '../../hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  montHeader: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function Invoices() {
  const { t } = useTranslation();
  //const [counter, setCounter] = React.useState(0);
  const {
    state: { invoices, paymentStatus, dateRange, local },
    setState,
  } = useInvoicesState();
  let { invoiceId, paramClinicId } = useParams();
  if (invoiceId) {
    invoiceId = parseInt(invoiceId, 10);
  }
  if (paramClinicId) {
    paramClinicId = parseInt(paramClinicId, 10);
  }

  const {
    userState: {
      user: { mmkId, clinicId, lang },
      appInfo,
    },
  } = useUserStateDispatch();

  const paidInvoice = !isEmpty(invoices.data)
    ? invoices.data.find((item) => item.invoiceDetails?.invoiceId)
    : null;

  const paidData =
    paidInvoice != null
      ? paidInvoice.invoiceDetails.paidData
      : { modalPaid: false };

  const openModalPaid = (item) => {
    //const needPaid = !item.isPaid && item.canBePaid;
    if (!paidData.modalPaid) {
      getInvoicePayInfo({
        setState,
        mmkId,
        clinicId: item.clinicId,
        invoiceId: item.id,
        isBlock: true,
      });
    }
  };
  const closeModal = () => {
    if (paidData.modalPaid) {
      setState({
        type: 'RESET_INVOCE_PAID_CLOSE_MODALE',
        payload: paidInvoice.id,
      });
    }
  };

  useEffect(() => {
    setState({
      type: 'REST_INVOICES',
    });
    if (invoiceId != null && paramClinicId != null) {
      getInvoiceById({
        setState,
        invoiceId,
        mmkId,
        clinicId: paramClinicId,
      });
    }

    if (invoiceId == null) {
      getInvoices({
        setState,
        mmkId,
        clinicId,
        paymentStatus,
        dateFrom:
          dateRange[0] != null ? dateToHL7(dateRange[0]) : null,
        maxDate:
          dateRange[1] != null ? dateToHL7(dateRange[1]) : null,
        local,
      });
    }
  }, [
    mmkId,
    paramClinicId,
    paymentStatus,
    dateRange,
    invoiceId,
    local,
  ]);

  useEffect(() => {
    if (paidData.modalPaid && appInfo.paidIsBowserWin) {
      window.winPaid = window.open(
        paidData.payLink,
        'Paid',
        'width=800,height=600,left=240,top=180',
      );
      const winOpenTimer = setInterval(function () {
        /** window was be closed  */
        if (window.winPaid == null || window.winPaid.closed) {
          closeModal();
          clearInterval(winOpenTimer);
        } else {
          window.winPaid.blur();
          window.winPaid.focus();
        }
      }, 200);
    }
    if (
      !paidData.modalPaid &&
      appInfo.paidIsBowserWin &&
      window.winPaid
    ) {
      window.winPaid.close();
    }
  }, [paidData && paidData.modalPaid]);

  // Counter
  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  const classes = useStyles();

  useInterval(
    () => {
      // console.log(
      //   'paidInvoice.fmInvoiceReq',
      //   paidInvoice.fmInvoiceReq,
      // );
      // console.log('paidData.fmInvoiceRecId', paidData.fmInvoiceRecId);

      if (
        !isEmpty(paidInvoice.fmInvoiceReq) &&
        paidData.fmInvoiceRecId
      ) {
        const fmInvoiceReq = paidInvoice.fmInvoiceReq.find(
          (it) => it.fmInvoiceReqId === paidData.fmInvoiceRecId,
        );

        ///console.log('fmInvoiceReq', fmInvoiceReq);

        if (fmInvoiceReq && fmInvoiceReq.sysStatus >= 4) {
          // console.log(
          //   'fmInvoiceReq.sysStatus',
          //   fmInvoiceReq.sysStatus,
          // );
          setState({
            type: 'RESET_INVOCE_PAID_DATA',
            payload: paidInvoice.id,
          });
          if (paidData.blockKey) {
            getInvoiceUnBlock({
              setState,
              type: 'CHECK_INVOICE',
              invoiceId: paidInvoice.id,
              mmkId,
              clinicId: paidInvoice.clinicId,
              blockKey: paidData.blockKey,
            });
          }
          return;
        }
      }
      getInvoiceById({
        setState,
        type: 'CHECK_INVOICE',
        invoiceId: paidInvoice.id,
        mmkId,
        clinicId: paidInvoice.clinicId,
        blockKey: paidData.blockKey,
      });
    },
    paidData.fmInvoiceRecId,
    3500,
  );

  const items = useMemo(
    () =>
      invoices.data.map((item, inx) => {
        const monthYearCurr = HL7ToDateToSrtDate(
          item.date,
          lang,
          'MMM YYYY',
          true,
        );
        const monthYearPrev = !isEmpty(invoices.data[inx - 1])
          ? HL7ToDateToSrtDate(
              invoices.data[inx - 1].date,
              lang,
              'MMM YYYY',
              true,
            )
          : '';

        return (
          <React.Fragment key={item.id}>
            {monthYearCurr !== monthYearPrev && (
              <Typography variant="h5" className={classes.montHeader}>
                {monthYearCurr}
              </Typography>
            )}
            <InvoicesItem
              invoice={item}
              invoiceId={invoiceId}
              paramClinicId={paramClinicId}
              openModalPaid={openModalPaid}
            />
          </React.Fragment>
        );
      }),
    [invoices.data, invoiceId, paramClinicId],
  );
  //console.log('invoices.isLoaded', invoices.isLoaded);
  return (
    <>
      {!appInfo.paidIsBowserWin && paidData.modalPaid && (
        <Modale
          onClose={closeModal}
          open={paidData.modalPaid}
          toggleModal={closeModal}
          title={`${t('INVOICES.BILL_PAYMENT')} №${
            paidData.invoiceId
          } ${HL7ToDateToSrtDate(paidInvoice.date, lang)} 
              ${HL7ToDateToTime(paidInvoice.date)}`}
          height={720}
          isScrollHide={true}
          maxWidth="md"
        >
          <IframePaid />
        </Modale>
      )}

      {!invoices.isLoaded ? (
        <Loading />
      ) : invoices.isLoaded && invoices.responseError != null ? (
        <Alert severity="error">
          <Typography variant="h6" component="p">
            {invoices.responseError}
          </Typography>
        </Alert>
      ) : invoices.isLoaded &&
        !isEmpty(invoices.data) &&
        invoiceId == null ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            invoices.pageIndex > 0 &&
            getInvoices({
              setState,
              mmkId,
              clinicId,
              paymentStatus,
              dateFrom:
                dateRange[0] != null ? dateToHL7(dateRange[0]) : null,
              maxDate:
                dateRange[1] != null ? dateToHL7(dateRange[1]) : null,
              pageIndex: invoices.pageIndex,
              local,
            })
          }
          hasMore={invoices.hasMore}
          loader={<Loading key={0} />}
        >
          {items}
        </InfiniteScroll>
      ) : invoices.isLoaded &&
        !isEmpty(invoices.data) &&
        invoiceId != null ? (
        <>
          {items}
          <Box mt={3} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/app/invoices"
            >
              {t('INVOICES.FILTER_ALL')}
            </Button>
          </Box>
        </>
      ) : invoices.isLoaded && isEmpty(invoices.data) ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_INVOICES')}
          </Typography>
        </Alert>
      ) : null}
    </>
  );
}
