import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { TextField as Input } from '@mui/material';
import Grid from '@mui/material/Grid2';
// context
import { useUserStateDispatch } from '../../context/UserContext';

import {
  confirmLogin,
  requestNewAccountConfirmationCode,
  singUpConfirmationCode,
} from '../../actions/user';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignInCode';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 46,
    textTransform: 'none',
    minWidth: 200,
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
  },

  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignInConfirm({
  valsTab,
  setValsTab,
  login,
  password,
  reLogin,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: {
      user: { lang },
      appInfo,
      isLoaded,
    },
    userDispatch,
  } = useUserStateDispatch();

  const [counter, setCounter] = React.useState(
    valsTab?.confirmationCodeInfo?.lifeTime
      ? valsTab.confirmationCodeInfo.lifeTime
      : 900,
  );

  const confirm = () => {
    if (valsTab.warningNote === 'CONFIRM_AUTH') {
      confirmLogin(
        userDispatch,
        values.code,
        navigate,
        lang,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
    if (valsTab.warningNote === 'CONFIRM_SINGUP') {
      singUpConfirmationCode(
        userDispatch,
        { login, password, confirmationCode: values.code },
        lang,
        navigate,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    confirm,
    validate,
    appInfo,
  );

  const reSendCode = () => {
    if (valsTab.warningNote === 'CONFIRM_AUTH') {
      reLogin();
    }
    if (valsTab.warningNote === 'CONFIRM_SINGUP') {
      requestNewAccountConfirmationCode(userDispatch, setValsTab, {
        ...valsTab,
        login,
        password,
      });
    }
  };

  // Counter
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return !isLoaded ? (
    <Grid size={12}>
      <Loading />
    </Grid>
  ) : (
    <>
      <Grid size={12}>
        {counter > 0 ? (
          <Input
            name="code"
            variant="outlined"
            value={values.code}
            onChange={handleChange}
            margin="normal"
            label={t('COMPONENT.CONFIRM_CODE')}
            type="code"
            fullWidth
            required
            error={errors?.code != null}
            helperText={errors?.code != null && errors?.code}
          />
        ) : (
          <Button
            color="primary"
            onClick={() => {
              reSendCode();
            }}
          >
            {t('COMPONENT.BUTTON_GET_CONFIRM_CODE')}
          </Button>
        )}
      </Grid>

      {counter > 0 && (
        <>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              className={classes.submitButton}
              variant="contained"
              color="primary"
              disabled={isEmpty(values.code) || counter === 0}
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              {t('SIGN.IN_BUTTON')}
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }} textAlign="right">
            <Typography variant="body1">
              {t('SIGN.IN_BY_QR_LIFETIME', { sec: counter })}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
}

export default SignInConfirm;
