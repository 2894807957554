import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import Loading from '../../components/Loading';

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getDoctorsTimeSlots } from '../../actions/visit';
import { useVisitState } from '../../context/VisitContext';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';
import isEmpty from '../../helpers';
import { Alert } from '@mui/material';
import SelectVisitKinds from '../../components/FormComponents/SelectVisitKinds';
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  buttonSlot: {
    margin: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
    minWidth: theme.spacing(9),
    padding: `${theme.spacing(0.3)} ${theme.spacing(0.2)}`,
    borderRadius: 3,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorInfoSlots = ({ toggleModalCreateVisit, setModalDoc }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();
  const {
    state: { visitData, cachedTimeSlots },
  } = useVisitState();

  const {
    doctor,
    visitDate,
    visitKind,
    visitKinds,
    clinic,
    patientDirectionId,
    isOnline,
    titleSpecializationName,
  } = visitData;

  const [newTimeSlots, setTimeSlots] = useState({
    timeSlotsLoaded: false,
    timeSlots: [],
    responseError: null,
  });
  const { timeSlotsLoaded, timeSlots, responseError } = newTimeSlots;
  const [localVisitKind, setLocalVisitKind] = useState(visitKind);

  const handleVisitKindChange = useCallback((value) => {
    if (!isEmpty(visitKinds)) {
      const newVisitKind = visitKinds.find(
        (item) => item.id === value,
      );
      setLocalVisitKind({ ...newVisitKind });
    }
  }, []);

  useEffect(() => {
    if (visitDate != '' && localVisitKind?.id)
      getDoctorsTimeSlots({
        setState: setTimeSlots,
        startDate: visitDate,
        clinicId: clinic.id,
        doctorId: doctor.id,
        plSubjId: doctor.plSubjId,
        visitKindId: localVisitKind.id,
        cached: cachedTimeSlots,
        isLocalState: true,
        consultationType: isOnline ? 'Online' : 'Offline',
        isDateRange: false,
      });

    // eslint-disable-next-line
  }, [visitDate, localVisitKind?.id]);

  return (
    <Box mb={2}>
      {!timeSlotsLoaded && localVisitKind?.id ? (
        <Loading />
      ) : responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(timeSlots) && localVisitKind?.id ? (
        <>
          <Typography
            variant="h5"
            component="p"
            className={classes.marginBottom}
          >
            {t('VISIT.SLOTS')}
          </Typography>

          <Typography variant="h6" mb={2} align="center" mr={4}>
            {HL7ToDateToSrtDate(visitDate, lang)}
          </Typography>
          {!isEmpty(visitKinds) && visitKinds.length > 1 ? (
            <Box mt={1} mb={2} mr={3}>
              <SelectVisitKinds
                selectedVisitKindId={localVisitKind?.id}
                handleVisitKindChange={handleVisitKindChange}
                visitKinds={visitKinds}
                variant="outlined"
                margin="none"
              />
            </Box>
          ) : (
            <Typography variant="body2" component="p" mt={2} mb={2}>
              {localVisitKind?.name}
            </Typography>
          )}
          {timeSlots.map((data) => (
            <Button
              color={data === visitDate ? 'primary' : 'secondary'}
              variant={data === visitDate ? 'outlined' : 'contained'}
              value={data}
              onClick={(e) => {
                setModalDoc(false);
                toggleModalCreateVisit({
                  visitDate: e.currentTarget.value,
                  doctor,
                  timeSlots,
                  visitKind: localVisitKind,
                  visitKinds,
                  isOnline,
                  clinic,
                  patientDirectionId,
                  titleSpecializationName,
                });
              }}
              key={data}
              className={classes.buttonSlot}
              data-testid={`but-doc-slot-${HL7ToDateToTime(data)}`}
            >
              {HL7ToDateToTime(data)}
            </Button>
          ))}
        </>
      ) : (
        <>
          <Typography variant="h6" mb={2} align="center" mr={4}>
            {HL7ToDateToSrtDate(visitDate, lang)}
          </Typography>
          <Alert
            severity="warning"
            style={{ marginBottom: 24 }}
            icon={false}
          >
            <Typography variant="h6" component="p">
              {localVisitKind?.id
                ? t('EMPTY_DATA.NO_SLOTS')
                : t('EMPTY_DATA.NO_VISIT_KIND')}
            </Typography>
          </Alert>
          <Button
            color="primary"
            variant="outlined"
            value={localVisitKind?.id}
            onClick={() => {
              setModalDoc(false);
              toggleModalCreateVisit({
                visitDate,
                doctor,
                timeSlots: null,
                localVisitKind,
                isOnline,
                clinic,
                patientDirectionId,
                titleSpecializationName,
              });
            }}
          >
            {t('COMPONENT.CONTACT_ME')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default DoctorInfoSlots;
