import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
  hl7ToUtc,
} from '../../helpers/dateFormat';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loading from '../../components/Loading';
import { Alert, Grid2, IconButton, Typography } from '@mui/material';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import { NUM_DAYS_RANGE } from '../../actions/visit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  buttonSlot: {
    margin: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
    minWidth: theme.spacing(9),
    padding: `${theme.spacing(0.3)} ${theme.spacing(0.2)}`,
    borderRadius: 3,
  },
  buttonOneVisitKind: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  dot: {
    fontSize: '1rem',
  },
}));

function getDates(visitDate) {
  const dateArray = [];
  const startDate = hl7ToUtc(visitDate);
  let currentDate = dayjs(startDate);
  const stopDate = dayjs(currentDate).add(NUM_DAYS_RANGE, 'days');
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('YYYYMMDD'));
    currentDate = dayjs(currentDate).add(1, 'days');
  }
  return dateArray;
}
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorSlots = ({
  timeSlots,
  timeSlotsIsLoaded,
  visitKind,
  visitDate,
  setModaleInfo,
  toggleModalCreateVisit,
  doctor,
  clinic,
  lang,
  isOnline,
  titleSpecializationName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dates = !isEmpty(timeSlots) ? getDates(visitDate) : [];

  const [value, setValue] = React.useState(dates[0]);

  const tabPanelSlots = (timeSlots || []).filter((slot) =>
    slot.includes(value),
  );

  const hasSlots = (dateTab) =>
    !isEmpty(
      (timeSlots || []).filter((slot) => slot.includes(dateTab)),
    );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ContactDoctor = () => (
    <>
      <Alert
        severity="warning"
        style={{ marginBottom: 24 }}
        icon={false}
      >
        <Typography variant="h6" component="p">
          {visitKind?.id
            ? t('EMPTY_DATA.NO_SLOTS')
            : t('EMPTY_DATA.NO_VISIT_KIND')}
        </Typography>
      </Alert>
      <Button
        data-testid="btn-contact-me"
        variant="outlined"
        color="primary"
        value={value || visitDate}
        onClick={(e) => {
          toggleModalCreateVisit({
            visitDate: e.currentTarget.value,
            doctor,
            timeSlots: null,
            visitKind,
            isOnline,
            clinic,
            titleSpecializationName,
          });
        }}
        className={classes.buttonOneVisitKind}
      >
        {t('COMPONENT.CONTACT_ME')}
      </Button>
    </>
  );

  const ConentPanel = ({ slots }) =>
    !isEmpty(slots) ? (
      slots.map((slot) => (
        <Button
          color="secondary"
          variant="contained"
          value={slot}
          onClick={(e) => {
            toggleModalCreateVisit({
              visitDate: e.currentTarget.value,
              doctor,
              timeSlots,
              visitKind,
              isOnline,
              clinic,
              titleSpecializationName,
            });
          }}
          key={slot}
          className={classes.buttonSlot}
          data-testid={`but-slot-${HL7ToDateToTime(slot)}`}
        >
          {HL7ToDateToTime(slot)}
        </Button>
      ))
    ) : (
      <ContactDoctor />
    );
  const tabSx = (theme) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      color: theme.palette.primary.light,
      minHeight: 32,
      borderRadius: 4,
      padding: '0 16px',
      textTransform: 'none',
      transition: 'color 0.3s ease-in-out',
      '&:hover': {
        color: 'green)',
      },
      '&.Mui-selected': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
      },
    },
  });
  const DateTabs = () => (
    <Box sx={{ width: '100%' }} mt={3}>
      <TabContext value={value}>
        <Grid2 container>
          <Grid2 size="grow">
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              textColor="primary"
              indicatorColor="secondary"
              sx={tabSx}
            >
              {dates.map((date) => (
                <Tab
                  label={HL7ToDateToSrtDate(date, lang)}
                  value={date}
                  key={date}
                  iconPosition="start"
                  icon={
                    hasSlots(date) && (
                      <FiberManualRecordIcon
                        className={classes.dot}
                      />
                    )
                  }
                  sx={(theme) =>
                    !hasSlots(date) &&
                    value !== date && {
                      color: `${theme.palette.text.secondary} !important`,
                    }
                  }
                />
              ))}
            </TabList>
          </Grid2>
          <Grid2 size="auto">
            <IconButton
              onClick={() => setModaleInfo(true)}
              aria-label="clear"
              color="primary"
              sx={{ position: 'relative', top: -3 }}
            >
              <InfoIcon />
            </IconButton>
          </Grid2>
        </Grid2>

        {dates.map((date) => (
          <TabPanel value={date} key={date} sx={{ px: 0 }}>
            <ConentPanel slots={tabPanelSlots} />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );

  return !timeSlotsIsLoaded ? (
    <Loading />
  ) : isEmpty(timeSlots) && timeSlotsIsLoaded ? (
    <ContactDoctor />
  ) : !isEmpty(timeSlots) && !isEmpty(visitKind) ? (
    <DateTabs />
  ) : null;
};

export default DoctorSlots;
