import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import LinkBase from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

import SignIn from '../../components/Forms/SignIn';
import SignUp from '../../components/Forms/SignUp';
import ChangePassword from '../../components/Forms/ChangePassword';

import UsersLanguageMenu from '../../components/Header/UsersLanguageMenu';

import logoRuStore from '../../images/ruStoreSmall.svg';
import logoAppStore from '../../images/appStoreSmall.svg';
import googlePlay from '../../images/googlePlaySmall.svg';

import SignInConfirm from '../../components/Forms/SignInConfirm';
import { makeStyles } from '@mui/styles';
import SignInQr from '../../components/Forms/SignInQr';
import { useUserStateDispatch } from '../../context/UserContext';
import custom from '../../custom';
import СlinicWebsiteButtonSign from '../../components/Header/СlinicWebsiteButtonSign';
import LoginDoctorButton from '../../components/Header/LoginDoctorButton';
import { getYearNow, isPetKey } from '../../helpers';
import config from '../../config';
import LoginIcon from '@mui/icons-material/Login';

const useStyles = makeStyles((theme) => ({
  all: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  topbg: {
    width: '100%',
    height: 112,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topbghr: {
    width: '100%',
    height: 1,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 84,
    left: 0,
    zIndex: 99,
  },
  top: {
    width: '100%',
    height: 84,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    zIndex: 100,
  },
  container: {
    width: '75%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    overflow: 'hide',
    [theme.breakpoints.down('lg')]: {
      width: '85%',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '97%',
    },
  },
  divide: {
    width: 2,
    height: theme.spacing(4),
    backgroundColor: '#B2B6C2',
    margin: '0 16px !important',
  },

  bottom: {
    width: '100%',
    backgroundColor: 'white',
  },
  bottomContainer: {
    height: '100%',

    paddingBottom: '2%',
    paddingRight: '10%',
    [theme.breakpoints.down('md')]: {
      paddingRight: '2%',
      paddingBottom: '3%',
    },
  },

  heroImage: {
    width: '94%',
  },

  copyright: {
    color: '#B2B6C2',
    fontSize: 14,
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(8),
  },
  marginGoogleButtons: {
    margin: `${theme.spacing(2.5)} ${theme.spacing(2)} 0 0`,
  },

  appLink: {
    paddingRight: theme.spacing(2),
  },
}));

function Sign() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo },
    userDispatch,
  } = useUserStateDispatch();

  const GooglePlayIcon = custom.GooglePlayIcon;
  const AppStoreIcon = custom.AppStoreIcon;
  const RuStoreIcon = custom.RuStoreIcon;

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    ...config.defLoginPassword,
    authIdentifiers: appInfo.authIdentifiers,
  });
  useEffect(() => {
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      authIdentifiers: appInfo.authIdentifiers,
    });
  }, [appInfo.authIdentifiers]);

  const forPet = appInfo?.uiType === 'petClinic';
  const inHead = t(isPetKey('SIGN.IN_HEAD', forPet));

  return (
    <Box className={classes.all}>
      <div className={classes.topbg}></div>
      <div className={classes.topbghr}></div>
      <div className={classes.top}>
        <div
          className={classes.container}
          style={{
            alignItems: 'center',
          }}
        >
          <div>
            <LinkBase href="/">
              <img src={custom.logoHero} alt={inHead} />
            </LinkBase>
          </div>
          {appInfo.showStoreLinks && !isMobile && (
            <>
              <Box
                sx={{
                  ml: { sm: 2, md: 3, lg: 9 },
                }}
              />
              {appInfo.linkAppStore !== '' && (
                <LinkBase href={appInfo.linkAppStore} target="_blank">
                  <img src={logoAppStore} alt={inHead} />
                </LinkBase>
              )}
              {appInfo.linkAppStore !== '' &&
                (appInfo.linkGooglePlay !== '' ||
                  appInfo.linkRuStore !== '') && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkGooglePlay !== '' && (
                <LinkBase
                  href={appInfo.linkGooglePlay}
                  target="_blank"
                >
                  <img src={googlePlay} alt={inHead} />
                </LinkBase>
              )}

              {(appInfo.linkAppStore !== '' ||
                appInfo.linkGooglePlay !== '') &&
                appInfo.linkRuStore !== '' && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkRuStore !== '' && (
                <LinkBase href={appInfo.linkRuStore} target="_blank">
                  <img src={logoRuStore} alt={inHead} />
                </LinkBase>
              )}
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flexGrow: 0.8 }} />
            {appInfo.showLanguageSwitch && <UsersLanguageMenu />}

            {appInfo.clinicWebsiteUrl && !isMobile && (
              <СlinicWebsiteButtonSign />
            )}

            {appInfo.isDoctorAuthEnabled && !isMobile && (
              <LoginDoctorButton />
            )}

            {valsTab.activeTabId !== 1 ? (
              <Button
                color="primary"
                className={classes.forgetButton}
                component="a"
                sx={(theme) => ({
                  mt: 0.5,
                  height: 32,
                  borderRadius: 1,
                  px: 2,
                  border: `1px solid ${theme.palette.primary.main}`,
                  '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                })}
                onClick={() => {
                  userDispatch({
                    type: 'LOADED',
                  });
                  setValsTab({ ...valsTab, activeTabId: 1 });
                }}
              >
                {t('SIGN.UP_BUTTON')}
              </Button>
            ) : (
              <Button
                color="primary"
                className={classes.forgetButton}
                component="a"
                sx={(theme) => ({
                  mt: 0.5,
                  height: 32,
                  borderRadius: 1,
                  px: 2,
                  border: `1px solid ${theme.palette.primary.main}`,
                  '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                })}
                onClick={() => {
                  userDispatch({
                    type: 'LOADED',
                  });
                  setValsTab({ ...valsTab, activeTabId: 0 });
                }}
                endIcon={<LoginIcon />}
              >
                {t('SIGN.IN_BUTTON')}
              </Button>
            )}
          </Box>
        </div>
      </div>

      <Grid
        container
        alignSelf="center"
        mx="auto"
        className={classes.container}
      >
        <Grid size={{ xs: 12, mb: 4 }} mt={16}>
          <Typography variant="h1">{inHead}</Typography>
        </Grid>
        <Grid size={{ sm: 12, md: 5 }}>
          {valsTab.activeTabId === 0 && (
            <SignIn valsTab={valsTab} setValsTab={setValsTab} />
          )}
          {valsTab.activeTabId === 1 && (
            <SignUp valsTab={valsTab} setValsTab={setValsTab} />
          )}
          {valsTab.activeTabId === 2 && (
            <ChangePassword
              valsTab={valsTab}
              setValsTab={setValsTab}
            />
          )}
          {valsTab.activeTabId === 3 && (
            <SignInQr
              setActiveTabId={() => {
                userDispatch({ type: 'LOADED' });
                setValsTab({
                  ...valsTab,
                  activeTabId: 0,
                });
              }}
            />
          )}
        </Grid>
        <Grid
          size={{ md: 0.5 }}
          sx={{
            display: { sm: 'none', md: 'block' },
          }}
        />
        <Grid
          size={{ md: 6 }}
          sx={{
            display: { sm: 'none', xs: 'none', md: 'block' },
          }}
        >
          <img
            src={custom.heroImg}
            alt={inHead}
            className={classes.heroImage}
          />
        </Grid>
        {valsTab.activeTabId === 0 && (
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" mt={2} mb={6}>
              {t(isPetKey('SIGN.IN_TEXT', forPet))}
            </Typography>
          </Grid>
        )}
        <Grid size={{ xs: 12 }} className={classes.bottomContainer}>
          <Divider />
          <Grid container>
            {appInfo.showStoreLinks && (
              <Grid className={classes.marginGoogleButtons}>
                {appInfo.linkGooglePlay !== '' && (
                  <LinkBase
                    href={appInfo.linkGooglePlay}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <GooglePlayIcon />
                  </LinkBase>
                )}
                {appInfo.linkAppStore !== '' && (
                  <LinkBase
                    href={appInfo.linkAppStore}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <AppStoreIcon />
                  </LinkBase>
                )}
                {appInfo.linkRuStore !== '' && (
                  <LinkBase
                    href={appInfo.linkRuStore}
                    target="_blank"
                    className={classes.appLink}
                  >
                    <RuStoreIcon />
                  </LinkBase>
                )}
              </Grid>
            )}
            <Grid className={classes.marginGoogleButtons}>
              {appInfo.clinicWebsiteUrl && isMobile && (
                <СlinicWebsiteButtonSign />
              )}
            </Grid>
            <Grid>
              <Typography className={classes.copyright}>
                {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
                <Link to="/" className={classes.link}>
                  {custom.defTitle}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Sign;
