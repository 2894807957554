/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useVisitState } from '../../context/VisitContext';
import { doctorFreeDates } from '../../actions/visit';

//import { useTranslation } from 'react-i18next';

import { dateToHL7, hl7ToUtc } from '../../helpers/dateFormat';

import { withStyles } from '@mui/styles';
import { Badge } from '@mui/material';
import { useLanguageValue } from '../../context/LanguageContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useUserStateDispatch } from '../../context/UserContext';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    top: 32,
    left: 20,
    width: 5,
    height: 5,
    minWidth: 5,

    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  //'&.MuiBadge-dot': {},
}))(Badge);

function ServerDay(props) {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(dateToHL7(props.day.toDate())) >= 0;

  return isSelected ? (
    <StyledBadge
      key={props.day.toString()}
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant="dot"
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </StyledBadge>
  ) : (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
    />
  );
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorInfoCalendar = ({ modalDoc }) => {
  //const { t } = useTranslation();
  const {
    languageState: { language },
  } = useLanguageValue();
  const {
    setState,
    state: { visitData, cachedTimeSlots },
  } = useVisitState();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const requestAbortController = React.useRef(null);
  const { doctor, visitKind, clinic, visitDate, isOnline } =
    visitData;
  const dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);
  const [docFreeDates, setDocFreeDates] = useState({
    isLoaded: true,
    data: [],
    selectedDate: dayjs(hl7ToUtc(visitDate)),
  });
  const shouldDisableDate = (date) => {
    return date.diff(dateNow) > 0;
  };

  useEffect(() => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }
    const visitDatesStart = dateToHL7(
      docFreeDates.selectedDate.toDate(),
    );
    const visitDatesEnd = dateToHL7(
      dayjs(docFreeDates.selectedDate.toDate())
        .endOf('month')
        .toDate(),
    );

    if (modalDoc && visitKind?.id)
      doctorFreeDates(
        setDocFreeDates,
        docFreeDates.selectedDate,
        clinic.id,
        doctor.id,
        visitKind?.id,
        visitDatesStart,
        visitDatesEnd,
        doctor.plSubjId,
        isOnline ? 'Online' : 'Offline',
        cachedTimeSlots,
      );
    return () => {
      // Cleanup function to abort any pending requests
      if (requestAbortController.current) {
        requestAbortController.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [visitDate, visitKind?.id]);

  // const requestAbortController = React.useRef(null);

  const handleDateChange = useCallback((newSelectedDate) => {
    const selectedDate = shouldDisableDate(newSelectedDate)
      ? newSelectedDate.toDate()
      : dateNow;

    setDocFreeDates({
      ...docFreeDates,
      selectedDate: selectedDate,
    });
    setState({
      type: 'REFREESH_DOCTOR_DATA',
      payload: {
        visitDate: dateToHL7(selectedDate),
      },
    });
  }, []);

  return (
    visitKind?.id && (
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={language}
      >
        <DateCalendar
          //id="date-picker-inline"
          disablePast
          value={dayjs(docFreeDates?.selectedDate)}
          onChange={handleDateChange}
          onMonthChange={handleDateChange}
          renderLoading={() => <DayCalendarSkeleton />}
          loading={!docFreeDates?.isLoaded}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays: docFreeDates.data,
            },
            switchViewButton: { sx: { display: 'none' } },
            calendarHeader: {
              sx: {
                '.MuiPickersCalendarHeader-label': {
                  cursor: 'default',
                },
              },
            },
          }}
          sx={(theme) => ({
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main} !important`,
              },
              '&.Mui-selected:hover': {
                backgroundColor: `${theme.palette.primary.light} !important`,
              },
            },
          })}
          maxDate={dayjs().add(appInfo.timeSlotsCacheDepth, 'day')}
          view="day"
        />
      </LocalizationProvider>
    )
  );
};

export default DoctorInfoCalendar;
