import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import useForm from '../../hooks/useForm';
import { TextField as Input, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Loading from '../Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import { validate } from './validationFeedback';
import { error } from '../../actions/user';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    margin: `${theme.spacing(2)} auto`,
    height: theme.spacing(6),
    maxWidth: theme.spacing(44),
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

function FeedBackForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: {
      user: { lastName, firstName, middleName, email },
      appInfo,
      serverResponse,
      isLoaded,
    },
    userDispatch,
  } = useUserStateDispatch();

  useEffect(() => {
    setValues({
      fio: `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}`,
      email,
    });

    // eslint-disable-next-line
  }, []);

  const submit = () => {
    const errorText = `${values.fio}\n------------------\n${values.email}\n------------------\n${values.errorText}`;
    error({
      dispatch: userDispatch,
      platform: 'web',
      errorText,
    });
  };

  const { values, errors, handleChange, handleSubmit, setValues } =
    useForm(submit, validate, appInfo);

  const disabled = !isEmpty(errors) || !lastName || !values.errorText;

  return (
    <>
      {!isLoaded ? (
        <Loading />
      ) : (
        serverResponse?.action == null && (
          <Grid container spacing={3}>
            <Grid size={12}>
              <Input
                name="fio"
                variant="outlined"
                autoComplete={'off'}
                value={values.fio || ''}
                onChange={handleChange}
                label={t('FEEDBACK.FORM_FIO')}
                type="text"
                fullWidth
                slotProps={{ maxLength: 50 }}
                required
                error={errors?.fio != null}
                helperText={errors?.fio != null && errors?.fio}
              />
            </Grid>
            <Grid size={12}>
              <Input
                name="email"
                variant="outlined"
                autoComplete={'off'}
                value={values.email || ''}
                onChange={handleChange}
                label={t('FEEDBACK.FORM_EMAIL')}
                type="email"
                fullWidth
                inputProps={{ maxLength: 50 }}
                required
                error={errors?.email != null}
                helperText={errors?.email != null && errors?.email}
              />
            </Grid>

            <Grid size={12}>
              <Input
                name="errorText"
                variant="outlined"
                value={values.errorText || ''}
                onChange={handleChange}
                label={t('FEEDBACK.FORM_TEXTERROR')}
                type="text"
                multiline
                rows={5.2}
                fullWidth
                required
                error={errors?.errorText != null}
                helperText={
                  errors?.errorText != null && errors?.errorText
                }
              />
            </Grid>

            <Grid size={12} textAlign={'center'}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                disabled={disabled}
              >
                {t('CALL_DOCTOR.BUTTON_CALL_DOCTOR')}
              </Button>
            </Grid>
          </Grid>
        )
      )}
    </>
  );
}

export default FeedBackForm;
