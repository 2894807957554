import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginUser } from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

import SignInForm from './SignInForm';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';

import SignInConfirm from './SignInConfirm';
import { useTranslation } from 'react-i18next';
import ShowResponse from '../FormComponents/ShowResponse';
import Loading from '../Loading';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab, setValsTab, hasButtonGoSignUp = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // global
  const {
    userDispatch,
    userState: { appInfo, isLoaded, serverResponse },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setValsTab,
      valsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const _appInfo = {
    ...appInfo,
    authIdentifiers: valsTab.authIdentifiers,
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(login, validate, _appInfo);

  const buttonLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_BUTTON_PHONE');
      }
      case 'phone': {
        return t('SIGN.IN_BUTTON_EMAIL');
      }
    }
  };
  const title = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_TITLE_EMAIL');
      }
      case 'phone': {
        return t('SIGN.IN_TITLE_PHONE');
      }
      default: {
        return t('SIGN.IN_TITLE');
      }
    }
  };
  const handleAuthIdentifiers = () => {
    const authIdentifiers =
      valsTab.authIdentifiers === 'email' ? 'phone' : 'email';
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      authIdentifiers,
    });
    const vals = {
      ...values,
      login: null,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  useEffect(() => {
    if (valsTab?.login && valsTab?.password) {
      setValues({
        login: valsTab.login,
        password: valsTab.password,
      });
      if (valsTab?.warningNote === 'CHANGED_PASS') {
        setTimeout(handleSubmit, 100);
      }
    }
  }, []);

  return (
    <Grid container spacing={1} mt={3} mb={3}>
      <Grid size={7}>
        <Typography variant="h3">{title()}</Typography>
      </Grid>

      <Grid size={5} textAlign="right">
        {appInfo.authIdentifiers !== 'both' && (
          <Button color="primary" onClick={handleAuthIdentifiers}>
            {buttonLabel()}
          </Button>
        )}
      </Grid>
      {!isLoaded ? (
        <Grid size={12} mt={3} mb={4}>
          <Loading />
        </Grid>
      ) : (
        <>
          <Grid size={12} mt={3} mb={2}>
            <ShowResponse serverResponse={serverResponse} />
          </Grid>
          {valsTab?.warningNote === 'CONFIRM_AUTH' ||
          valsTab?.warningNote === 'CONFIRM_SINGUP' ? (
            <>
              <Grid size={12} mt={3} mb={2}>
                <Typography variant="h4">
                  {t('SIGN.CH_CONFIRM_CODE')}
                </Typography>
              </Grid>
              <SignInConfirm
                valsTab={valsTab}
                setValsTab={setValsTab}
                login={values.login}
                password={values.password}
                reLogin={login}
              />
            </>
          ) : (
            <SignInForm
              values={values}
              errors={errors}
              setValues={setValues}
              setErrors={setErrors}
              validate={validate}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              valsTab={valsTab}
              setValsTab={setValsTab}
              appInfo={appInfo}
              handlePhoneChange={handlePhoneChange}
              hasButtonGoSignUp={hasButtonGoSignUp}
            />
          )}
        </>
      )}
    </Grid>
  );
}

export default SignIn;
