import React, {
  useReducer,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from 'react';
import {
  changeUser,
  getDefaultMmkId,
  getDictsData,
  getLang,
  loginByToken,
  savedEntites,
} from '../actions/user';

import isEmpty from '../helpers';
import { appInfoInit, structureAdd } from '../helpers/utils';
import structure from '../components/Sidebar/structure';
const UserContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_PATIENT':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoaded: true,
      };

    case 'SIGN_OUT_SUCCESS':
      console.log(' ==== SIGN_OUT_SUCCESS === ');
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticatedDoctor: false,
        serverResponse: null,
        user: {},
        doctor: {},
        isLoaded: true,
      };
    case 'SIGN_UP_AGREEMENT':
      return { ...state, ...action.payload };
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,

        isLoaded: true,
      };
    case 'SET_USER_WITHOUT_LOADING':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_DICTS': {
      /** ---------------------- save to localStorage ------------------------ */

      Object.keys(action.payload).forEach((key) => {
        localStorage.setItem(
          key,
          JSON.stringify(action.payload[key]),
        );
      });

      /** ---------------------- clinics ------------------------ */

      const clinics = action.payload.clinics
        .filter((it) => it.isVisible)
        .map((item) => ({
          ...item,
          name_old: item.name,
          name: item.title ? item.title : item.name,
        }));

      /** ---------------------- specs ------------------------ */

      const specs = action.payload.specs.filter(
        (it) => it?.name != null,
      );

      /** ---------------------- appInfo ------------------------ */

      const appInfo = appInfoInit(action.payload.appInfo);

      /** ---------------------- structure ------------------------ */

      const structure = structureAdd(
        action.payload.calypsoData,
        action.payload.appInfo,
      );

      /** ---------------------- notificationsTypes ------------------------ */

      let { notificationsTypes } = action.payload;
      if (
        !isEmpty(appInfo) &&
        appInfo.showFilterOnline != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !appInfo.showFilterOnline
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'OnlineConsultation',
        );
      }

      if (
        !isEmpty(appInfo) &&
        appInfo.isDirectionsEnabled != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !appInfo.isDirectionsEnabled
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'NewDirections',
        );
      }

      if (
        !isEmpty(appInfo) &&
        appInfo.isPrescribedDrugsEnabled != null &&
        !isEmpty(action.payload?.notificationsTypes) &&
        !appInfo.isPrescribedDrugsEnabled
      ) {
        notificationsTypes = notificationsTypes.filter(
          (item) => item.name !== 'NewAssignments',
        );
      }
      delete action.payload.notificationsTypes;
      /** ---------------------- end notificationsTypes ------------------------ */

      const payload = {
        ...action.payload,
        clinics,
        specs,
        appInfo,
        structure,
        notificationsTypes,
      };

      console.log('\n === DICTS ==== \n', payload);

      return {
        ...state,
        ...payload,
        isLoaded: true,
      };
    }

    case 'SET_USER_PHOTO':
      return {
        ...state,
        ...action.payload,
        mmkLinkedList: state.mmkLinkedList.map((item) =>
          item.number === action.payload.user.mmkId
            ? { ...item, photo: action.payload.user.photo }
            : item,
        ),
        isLoaded: true,
      };

    case 'SET_MMK_LIST_PHOTO':
      return {
        ...state,
        mmkLinkedList: state.mmkLinkedList.map((item) =>
          item.number === action.payload.mmkId
            ? { ...item, photo: action.payload.photo }
            : item,
        ),
      };

    case 'SET_MMK_LINKED_LIST': {
      localStorage.setItem(
        'mmkLinkedList',
        JSON.stringify(action.payload),
      );

      return {
        ...state,
        mmkLinkedList: action.payload,
        //isMmkLinkedListLoaded: true,
        isLoaded: true,
      };
    }

    case 'LOGIN_DOCTOR':
      localStorage.setItem(
        'doctor',
        JSON.stringify({
          ...action.payload.doctor,
        }),
      );
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...action.payload.user,
        }),
      );
      localStorage.setItem(
        'mmkRecordTypes',
        JSON.stringify(action.payload.mmkRecordTypes),
      );
      localStorage.setItem(
        'specs',
        JSON.stringify(action.payload.specs),
      );

      return {
        ...state,
        ...action.payload,
        isAuthenticatedDoctor: true,

        error: '',
      };
    case 'SET_DOC_DATA':
      return {
        ...state,
        doctor: {
          ...action.payload,
        },

        error: '',
      };
    case 'SET_CLINICS': {
      const clinics = (action.payload || []).filter(
        (it) => it.isVisible,
      );

      return {
        ...state,
        clinics,
        error: '',
      };
    }
    case 'LOADING':
      return {
        ...state,
        isLoaded: false,
        error: '',
        serverResponse: null,
      };
    case 'LOADED':
      return {
        ...state,
        isLoaded: true,
        error: '',
        serverResponse: null,
      };

    case 'SET_CHAT_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },

        error: '',
      };

    case 'SET_SERVER_RESPONSE':
      return {
        ...state,

        isLoaded: true,
        serverResponse: action.payload,
      };
    case 'SET_NOTIFY_SETTINGS':
      return {
        ...state,
        isLoaded: true,
        notificationSettings: action.payload,
      };

    case 'LOGIN_FAILURE':
      return {
        ...state,

        serverResponse: { message: 'Something went wrong' },
      };
    case 'LOGIN_DOCTOR_FAILURE':
      return {
        ...state,

        serverResponse: 'Something went wrong',
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function UserProvider({ children }) {
  const authToken = localStorage.getItem('authToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const lang = getLang();
  const user = JSON.parse(localStorage.getItem('user')) ?? {
    lang,
    clinicId: 0,
    mmkId: null,
  };
  const mmkLinkedList =
    JSON.parse(localStorage.getItem('mmkLinkedList')) ?? [];
  const doctor = JSON.parse(localStorage.getItem('doctor')) ?? {};

  const isAuthenticated =
    authToken != null && user?.mmkId != null && isEmpty(doctor);

  const appInfo = appInfoInit({});

  useEffect(() => {
    const init = async () => {
      getDictsData(userDispatch);
      loginByToken(
        userDispatch,
        lang,
        appInfo?.isAnonymousChildrenEnabled,
      );
      if (isAuthenticated) {
        const defaultMmkId = await getDefaultMmkId();

        if (user?.mmkId == null || defaultMmkId != user?.mmkId) {
          changeUser({
            dispatch: userDispatch,
            mmkId: defaultMmkId,
            clinicId: 0,
            mmkLinkedList,
            isAnonymousChildrenEnabled:
              appInfo?.isAnonymousChildrenEnabled,
            showLoading: false,
            phoneParent: user?.phoneParent,
          });
        }
      }
    };
    init();
  }, [lang]);

  const isAuthenticatedDoctor =
    !isAuthenticated && !!authToken && !isEmpty(doctor);

  const dicts = savedEntites.reduce((acc, cur) => {
    if (cur === 'appInfo')
      acc[cur] =
        { ...appInfo, ...JSON.parse(localStorage.getItem(cur)) } ??
        appInfo;
    else acc[cur] = JSON.parse(localStorage.getItem(cur)) ?? [];
    return acc;
  }, {});
  //console.log('dicts', dicts);

  const [userState, userDispatch] = useReducer(userReducer, {
    /** user  */
    isAuthenticated,
    user: { ...user, lang },
    mmkLinkedList,
    ...dicts,

    isLoaded: true,
    //isMmkLinkedListLoaded: true,
    serverResponse: null,
    /** doctor  */
    isAuthenticatedDoctor,
    doctor: { ...doctor, step: 0 },
    error: '',
    authToken,
    refreshToken,
    structure,
    notificationSettings: [],
  });
  const value = useMemo(
    () => ({ userState, userDispatch }),
    [userState],
  );
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

function useUserStateDispatch() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useUserStateDispatch must be used within a UserProvider',
    );
  }
  return context;
}

export { UserProvider, useUserStateDispatch };
