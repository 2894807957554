import React from 'react';
import { ChatIndex } from '@pmt/chat';
import { useUserStateDispatch } from '../../context/UserContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Chat = ({
  lang = 'ru',
  activeGroupId,
  activeChatUserId,
  inModale = false,
  hideRooms = false,
}) => {
  const {
    userState: { appInfo, authToken, refreshToken },
  } = useUserStateDispatch();

  return (
    <ChatIndex
      lang={lang}
      chatBaseURLApi={appInfo.chatBaseURLApi}
      chatWsUrl={appInfo.chatWsUrl}
      chatWsPath={appInfo.chatWsPath}
      activeGroupId={activeGroupId}
      activeChatUserId={activeChatUserId}
      hideRooms={hideRooms}
      inModale={inModale}
      token={authToken}
      refreshToken={refreshToken}
    />
  );
};

export default Chat;
