import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import { useUserStateDispatch } from '../../context/UserContext';
import SignInQr from '../Forms/SignInQr';
import SignIn from '../Forms/SignIn';
import SignUp from '../Forms/SignUp';
import ChangePassword from '../Forms/ChangePassword';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign() {
  const classes = useStyles();
  // global

  const {
    userState: { appInfo },
    userDispatch,
  } = useUserStateDispatch();

  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    ...config.defLoginPassword,
    authIdentifiers: appInfo.authIdentifiers,
  });

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });

    setValsTab({
      ...valsTab,
      activeTabIdPrev: valsTab.activeTabId,
      activeTabId,
    });
  };

  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 0 ? (
        <SignIn
          valsTab={valsTab}
          setValsTab={setValsTab}
          hasButtonGoSignUp={true}
        />
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          valsTab={valsTab}
          setValsTab={setValsTab}
          hasButtonGoSignIn={true}
        />
      ) : valsTab.activeTabId === 2 ? (
        <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
      ) : valsTab.activeTabId === 3 ? (
        <SignInQr setActiveTabId={setActiveTabId} />
      ) : null}
    </Box>
  );
}

export default Sign;
