import React from 'react';
import { makeStyles } from '@mui/styles';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import UserAvatarCard from '../../components/UserAvatar/UserAvatarCard';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import { isPetKey } from '../../helpers';
import { useVisitState } from '../../context/VisitContext';

const useStyles = makeStyles((theme) => ({
  avatarInfo: {
    marginTop: theme.spacing(2),
    color: '#fff',
  },
}));

const DoctorInfoUserAvatarCard = () => {
  const { t } = useTranslation();

  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const {
    state: {
      visitData: { doctor, titleSpecializationName },
    },
  } = useVisitState();
  //console.log(doctor?.lastName);
  const classes = useStyles();
  const src = doctor?.photoUrl
    ? `${config.baseURLApi}${doctor?.photoUrl}&appCode=${config.APP_CODE}&imageSize=small`
    : '';
  const fio = `${doctor?.lastName} ${doctor?.firstName ?? ''} ${
    doctor?.middleName ?? ''
  }`;

  const specializationName = titleSpecializationName
    ? titleSpecializationName
    : doctor.specializationInfo;

  const forPet = appInfo?.uiType === 'petClinic';
  return (
    <UserAvatarCard fio={fio} src={src}>
      <Typography
        variant="subtitle1"
        component="p"
        className={classes.avatarInfo}
      >
        {specializationName} {doctor?.degree}
      </Typography>

      {doctor?.workExperience != null && (
        <Typography
          variant="body2"
          component="p"
          className={classes.avatarInfo}
        >
          {t(isPetKey('VISIT.DR_WORKEXPERIENCE', forPet), {
            yars: doctor?.workExperience,
          })}
        </Typography>
      )}

      {appInfo.showDoctorsRating && (
        <Rating
          name="read-only"
          value={doctor?.rating}
          size="small"
          precision={0.5}
          style={{ color: 'white' }}
          emptyIcon={
            <StarBorderIcon
              style={{ color: 'white' }}
              fontSize="inherit"
            />
          }
          readOnly
        />
      )}
    </UserAvatarCard>
  );
};

export default DoctorInfoUserAvatarCard;
